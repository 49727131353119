import {
  Modal,
  TextInput,
} from 'carbon-components-react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';

import { usePLCValue, usePLCWrite } from '../../../features/plc/context';
import { useProgramHandle } from '../hooks/context';

function LoadingTempModal({ isOpen, close }) {
  const programHandle = useProgramHandle();
  const write = usePLCWrite();

  const capSideLoadingTemp = usePLCValue(`${programHandle}.fCapSideLoadingTemp`);
  const chipSideLoadingTemp = usePLCValue(`${programHandle}.fChipSideLoadingTemp`);
  const initialValues = useMemo(() => ({
    [`${programHandle}.fCapSideLoadingTemp`]: capSideLoadingTemp || 0,
    [`${programHandle}.fChipSideLoadingTemp`]: chipSideLoadingTemp || 0,
  }), [programHandle, capSideLoadingTemp, chipSideLoadingTemp]);

  const validationSchema = useMemo(() => Yup.object().shape({
    [`${programHandle}.fCapSideLoadingTemp`]: Yup.number().required('Required'),
    [`${programHandle}.fChipSideLoadingTemp`]: Yup.number().required('Required'),
  }), [programHandle]);

  const transforms = useMemo(() => [
    {
      inHandle: `${programHandle}.fCapSideLoadingTemp`,
      outHandle: `${programHandle}.fbCapSideBondingHFTS.fRearHeaterEngagedSetpoint`,
      transform: (v) => v,
    },
    {
      inHandle: `${programHandle}.fCapSideLoadingTemp`,
      outHandle: `${programHandle}.fCapSideLoadingTempDisengaged`,
      transform: (v) => v - 5,
    },
    {
      inHandle: `${programHandle}.fCapSideLoadingTemp`,
      outHandle: `${programHandle}.fbCapSideBondingHFTS.fRearHeaterDisengagedSetpoint`,
      transform: (v) => v - 5,
    },
    {
      inHandle: `${programHandle}.fChipSideLoadingTemp`,
      outHandle: `${programHandle}.fbChipSideBondingHFTS.fRearHeaterEngagedSetpoint`,
      transform: (v) => v,
    },
    {
      inHandle: `${programHandle}.fChipSideLoadingTemp`,
      outHandle: `${programHandle}.fChipSideLoadingTempDisengaged`,
      transform: (v) => v - 5,
    },
    {
      inHandle: `${programHandle}.fChipSideLoadingTemp`,
      outHandle: `${programHandle}.fbChipSideBondingHFTS.fRearHeaterDisengagedSetpoint`,
      transform: (v) => v - 5,
    },
  ], [programHandle]);

  if (typeof document === 'undefined') {
    return null;
  }

  return ReactDOM.createPortal(
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(values) => {
        const newValues = { ...values };
        transforms.forEach(({ inHandle, outHandle, transform }) => {
          newValues[outHandle] = Math.round(transform(values[inHandle]) * 10) / 10;
        });
        [
          `${programHandle}.fCapSideLoadingTemp`,
          `${programHandle}.fCapSideLoadingTempDisengaged`,
          `${programHandle}.fbCapSideBondingHFTS.fRearHeaterEngagedSetpoint`,
          `${programHandle}.fbCapSideBondingHFTS.fRearHeaterDisengagedSetpoint`,
          `${programHandle}.fChipSideLoadingTemp`,
          `${programHandle}.fChipSideLoadingTempDisengaged`,
          `${programHandle}.fbChipSideBondingHFTS.fRearHeaterEngagedSetpoint`,
          `${programHandle}.fbChipSideBondingHFTS.fRearHeaterDisengagedSetpoint`,
        ].forEach((handle) => {
          write({ handle, value: newValues[handle] });
        });

        write({ handle: `${programHandle}.fbCapSideBondingHFTS.eAutomationState`, value: 2 });
        write({ handle: `${programHandle}.fbChipSideBondingHFTS.eAutomationState`, value: 2 });

        write({ handle: `${programHandle}.bCapSideHotBlockHeaterSystemOn`, value: true });
        write({ handle: `${programHandle}.bCapSideFrontHeaterSystemOn`, value: true });
        write({ handle: `${programHandle}.bCapSideRearHeaterSystemOn`, value: true });
        write({ handle: `${programHandle}.bChipSideHotBlockHeaterSystemOn`, value: true });
        write({ handle: `${programHandle}.bChipSideFrontHeaterSystemOn`, value: true });
        write({ handle: `${programHandle}.bChipSideRearHeaterSystemOn`, value: true });
        close();
      }}
    >
      {({
        values, handleSubmit, handleBlur, handleChange, errors,
      }) => (
        <Modal
          size="sm"
          open={isOpen}
          modalHeading="Go to loading temp"
          primaryButtonText="Start"
          secondaryButtonText="Cancel"
          onSecondarySubmit={() => { close(); }}
          onRequestSubmit={handleSubmit}
          primaryButtonDisabled={!!Object.keys(errors).length}
          onRequestClose={() => { close(); }}
        >
          <div className="bx--row">
            <div className="bx--col">
              <div className="bx--row">
                <div className="bx--col">
                  <TextInput
                    labelText="Cap Side Loading Temperature"
                    id={`['${programHandle}.fCapSideLoadingTemp']`}
                    name={`['${programHandle}.fCapSideLoadingTemp']`}
                    value={values[`${programHandle}.fCapSideLoadingTemp`]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={!!errors[`${programHandle}.fCapSideLoadingTemp`]}
                    invalidText={errors[`${programHandle}.fCapSideLoadingTemp`]}
                  />
                </div>
                <div className="bx--col">
                  <TextInput
                    labelText="Chip Side Loading Temperature"
                    id={`['${programHandle}.fChipSideLoadingTemp']`}
                    name={`['${programHandle}.fChipSideLoadingTemp']`}
                    value={values[`${programHandle}.fChipSideLoadingTemp`]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={!!errors[`${programHandle}.fChipSideLoadingTemp`]}
                    invalidText={errors[`${programHandle}.fChipSideLoadingTemp`]}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </Formik>,
    document.body,
  );
}

LoadingTempModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default LoadingTempModal;
