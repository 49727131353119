import {
  Accordion,
  AccordionItem,
  Modal,
  TextInput,
} from 'carbon-components-react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';

import { usePLCValues, usePLCWrite } from '../../../features/plc/context';
import { useProgramHandle } from '../hooks/context';

function SetupConfigModal({ isOpen, close }) {
  const programHandle = useProgramHandle();
  const write = usePLCWrite();

  const handleBases = [
    'tPresoakTime',
    'tBondTime',
    'tSuperheatWaitTime',
    'tAnnealingWaitTime',

    'fCapSideLoadingTemp',
    'fChipSideLoadingTemp',

    'fCapSideSetpointTolerance',
    'fChipSideSetpointTolerance',

    'fCapSideBondingTemp',
    'fChipSideBondingTemp',

    'fChipSideAnnealingTemp',
    'fCapSideAnnealingTemp',

    'fChipSideSuperheatTemp',
    'fChipSideSuperheatTolerance',
    'fCapSideSuperheatTemp',
    'fCapSideSuperheatTolerance',

    'fChipSideFinalTemp',
    'fCapSideFinalTemp',
  ];

  const {
    handles, validationSchema, transforms, timeHandles,
  } = useMemo(() => {
    if (!programHandle) {
      return {
        handles: [],
        validationSchema: Yup.object().shape({}),
        transforms: [],
        timeHandles: [],
      };
    }

    const handleNames = [];
    const yupSchema = {};
    handleBases.forEach((handleBase) => {
      const handle = `${programHandle}.${handleBase}`;
      handleNames.push(handle);
      yupSchema[handle] = Yup
        .number()
        .typeError(`${handleBase} must be valid number`)
        .required(`${handleBase} is required`);
    });

    return {
      handles: handleNames,
      validationSchema: Yup.object().shape(yupSchema),
      transforms: [
        {
          inHandle: `${programHandle}.fCapSideLoadingTemp`,
          outHandle: `${programHandle}.fCapSideLoadingTempDisengaged`,
          transform: (v) => v - 5,
        },
        {
          inHandle: `${programHandle}.fChipSideLoadingTemp`,
          outHandle: `${programHandle}.fChipSideLoadingTempDisengaged`,
          transform: (v) => v - 5,
        },
        {
          inHandle: `${programHandle}.fCapSideBondingTemp`,
          outHandle: `${programHandle}.fCapSideBondingTempDisengaged`,
          transform: (v) => (1.1628 * v) + 3.2558,
        },
        {
          inHandle: `${programHandle}.fChipSideBondingTemp`,
          outHandle: `${programHandle}.fChipSideBondingTempDisengaged`,
          transform: (v) => v - 5,
        },
        {
          inHandle: `${programHandle}.fChipSideAnnealingTemp`,
          outHandle: `${programHandle}.fChipSideAnnealingTempDisengaged`,
          transform: (v) => v - 10,
        },
        {
          inHandle: `${programHandle}.fCapSideAnnealingTemp`,
          outHandle: `${programHandle}.fCapSideAnnealingTempDisengaged`,
          transform: (v) => (1.1628 * v) + 3.2558,
        },
        {
          inHandle: `${programHandle}.fChipSideSuperheatTemp`,
          outHandle: `${programHandle}.fChipSideSuperheatTempDisengaged`,
          transform: (v) => v - 5,
        },
        {
          inHandle: `${programHandle}.fCapSideSuperheatTemp`,
          outHandle: `${programHandle}.fCapSideSuperheatTempDisengaged`,
          transform: (v) => (1.1628 * v) + 3.2558,
        },
        {
          inHandle: `${programHandle}.fCapSideFinalTemp`,
          outHandle: `${programHandle}.fCapSideFinalTempDisengaged`,
          transform: (v) => v - 5,
        },
        {
          inHandle: `${programHandle}.fChipSideFinalTemp`,
          outHandle: `${programHandle}.fChipSideFinalTempDisengaged`,
          transform: (v) => v - 5,
        },
      ],
      timeHandles: [
        `${programHandle}.tPresoakTime`,
        `${programHandle}.tBondTime`,
        `${programHandle}.tSuperheatWaitTime`,
        `${programHandle}.tAnnealingWaitTime`,
      ],
    };
  }, [programHandle]);

  const plcValues = usePLCValues(handles);

  const initialValues = useMemo(() => {
    const values = handles.reduce(
      (acc, handle) => ({ ...acc, [handle]: plcValues[handle] || 0 }),
      {},
    );
    if (!isOpen) {
      return values;
    }
    transforms.forEach(({ inHandle, outHandle, transform }) => {
      if (plcValues[inHandle] !== undefined) {
        values[outHandle] = Math.round(transform(plcValues[inHandle]) * 10) / 10;
      } else {
        values[outHandle] = 0;
      }
    });
    timeHandles.forEach((handle) => {
      if (plcValues[handle] !== undefined) {
        values[handle] = plcValues[handle] / 1000;
      } else {
        values[handle] = 0;
      }
    });
    return values;
  }, [isOpen, handles, transforms, plcValues]);

  if (typeof document === 'undefined') {
    return null;
  }

  return ReactDOM.createPortal(
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(values) => {
        const newValues = { ...values };
        const newHandles = [...handles];
        transforms.forEach(({ inHandle, outHandle, transform }) => {
          newValues[outHandle] = Math.round(transform(values[inHandle]) * 10) / 10;
          newHandles.push(outHandle);
        });
        timeHandles.forEach((handle) => {
          newValues[handle] = values[handle] * 1000;
        });
        newHandles.forEach((handle) => {
          write({ handle, value: newValues[handle] });
        });

        close();
      }}
    >
      {({
        values, handleSubmit, handleBlur, handleChange, setFieldValue, errors,
      }) => {
        for (let i = 0; i < transforms.length; i += 1) {
          const { inHandle, outHandle, transform } = transforms[i];
          useEffect(() => {
            if (Number.isNaN(parseFloat(values[inHandle]))) {
              setFieldValue(`['${outHandle}']`, 0);
            } else {
              setFieldValue(`['${outHandle}']`, Math.round(transform(parseFloat(values[inHandle])) * 10) / 10);
            }
          }, [values[inHandle]]);
        }

        return (
          <Modal
            size="lg"
            open={isOpen}
            modalHeading="Initialize config"
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            onSecondarySubmit={() => { close(); }}
            onRequestSubmit={handleSubmit}
            primaryButtonDisabled={!!Object.keys(errors).length}
            onRequestClose={() => { close(); }}
          >
            <div className="bx--row">
              <div className="bx--col-lg-4">
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      labelText="Presoak Time (s)"
                      id={`['${programHandle}.tPresoakTime']`}
                      name={`['${programHandle}.tPresoakTime']`}
                      value={values[`${programHandle}.tPresoakTime`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.tPresoakTime`]}
                      invalidText={errors[`${programHandle}.tPresoakTime`]}
                    />
                  </div>
                </div>
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      labelText="Bond Time (s)"
                      id={`['${programHandle}.tBondTime']`}
                      name={`['${programHandle}.tBondTime']`}
                      value={values[`${programHandle}.tBondTime`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.tBondTime`]}
                      invalidText={errors[`${programHandle}.tBondTime`]}
                    />
                  </div>
                </div>
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      labelText="Superheat Wait Time (s)"
                      id={`['${programHandle}.tSuperheatWaitTime']`}
                      name={`['${programHandle}.tSuperheatWaitTime']`}
                      value={values[`${programHandle}.tSuperheatWaitTime`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.tSuperheatWaitTime`]}
                      invalidText={errors[`${programHandle}.tSuperheatWaitTime`]}
                    />
                  </div>
                </div>
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      labelText="Annealing Wait Time (s)"
                      id={`['${programHandle}.tAnnealingWaitTime']`}
                      name={`['${programHandle}.tAnnealingWaitTime']`}
                      value={values[`${programHandle}.tAnnealingWaitTime`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.tAnnealingWaitTime`]}
                      invalidText={errors[`${programHandle}.tAnnealingWaitTime`]}
                    />
                  </div>
                </div>
              </div>
              <div className="bx--col">
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      labelText="Cap Side Loading Temperature"
                      id={`['${programHandle}.fCapSideLoadingTemp']`}
                      name={`['${programHandle}.fCapSideLoadingTemp']`}
                      value={values[`${programHandle}.fCapSideLoadingTemp`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.fCapSideLoadingTemp`]}
                      invalidText={errors[`${programHandle}.fCapSideLoadingTemp`]}
                    />
                  </div>
                  <div className="bx--col">
                    <TextInput
                      labelText="Chip Side Loading Temperature"
                      id={`['${programHandle}.fChipSideLoadingTemp']`}
                      name={`['${programHandle}.fChipSideLoadingTemp']`}
                      value={values[`${programHandle}.fChipSideLoadingTemp`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.fChipSideLoadingTemp`]}
                      invalidText={errors[`${programHandle}.fChipSideLoadingTemp`]}
                    />
                  </div>
                </div>
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      labelText="Cap Side Bonding Temperature"
                      id={`['${programHandle}.fCapSideBondingTemp']`}
                      name={`['${programHandle}.fCapSideBondingTemp']`}
                      value={values[`${programHandle}.fCapSideBondingTemp`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.fCapSideBondingTemp`]}
                      invalidText={errors[`${programHandle}.fCapSideBondingTemp`]}
                    />
                  </div>
                  <div className="bx--col">
                    <TextInput
                      labelText="Chip Side Bonding Temperature"
                      id={`['${programHandle}.fChipSideBondingTemp']`}
                      name={`['${programHandle}.fChipSideBondingTemp']`}
                      value={values[`${programHandle}.fChipSideBondingTemp`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.fChipSideBondingTemp`]}
                      invalidText={errors[`${programHandle}.fChipSideBondingTemp`]}
                    />
                  </div>
                </div>
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      labelText="Cap Side Bonding Setpoint Tolerance Band"
                      id={`['${programHandle}.fCapSideSetpointTolerance']`}
                      name={`['${programHandle}.fCapSideSetpointTolerance']`}
                      value={values[`${programHandle}.fCapSideSetpointTolerance`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="bx--col">
                    <TextInput
                      labelText="Chip Side Heater Setpoint Tolerance Band"
                      id={`['${programHandle}.fChipSideSetpointTolerance']`}
                      name={`['${programHandle}.fChipSideSetpointTolerance']`}
                      value={values[`${programHandle}.fChipSideSetpointTolerance`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.fChipSideSetpointTolerance`]}
                      invalidText={errors[`${programHandle}.fChipSideSetpointTolerance`]}
                    />
                  </div>
                </div>
              </div>
              <div className="bx--col">
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      labelText="Cap Side Superheat Temperature"
                      id={`['${programHandle}.fCapSideSuperheatTemp']`}
                      name={`['${programHandle}.fCapSideSuperheatTemp']`}
                      value={values[`${programHandle}.fCapSideSuperheatTemp`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.fCapSideSuperheatTemp`]}
                      invalidText={errors[`${programHandle}.fCapSideSuperheatTemp`]}
                    />
                  </div>
                  <div className="bx--col">
                    <TextInput
                      labelText="Chip Side Superheat Temperature"
                      id={`['${programHandle}.fChipSideSuperheatTemp']`}
                      name={`['${programHandle}.fChipSideSuperheatTemp']`}
                      value={values[`${programHandle}.fChipSideSuperheatTemp`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.fChipSideSuperheatTemp`]}
                      invalidText={errors[`${programHandle}.fChipSideSuperheatTemp`]}
                    />
                  </div>
                </div>
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      labelText="Cap Side Superheat Tolerance Band"
                      id={`['${programHandle}.fCapSideSuperheatTolerance']`}
                      name={`['${programHandle}.fCapSideSuperheatTolerance']`}
                      value={values[`${programHandle}.fCapSideSuperheatTolerance`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.fCapSideSuperheatTolerance`]}
                      invalidText={errors[`${programHandle}.fCapSideSuperheatTolerance`]}
                    />
                  </div>
                  <div className="bx--col">
                    <TextInput
                      labelText="Chip Side Superheat Tolerance Band"
                      id={`['${programHandle}.fChipSideSuperheatTolerance']`}
                      name={`['${programHandle}.fChipSideSuperheatTolerance']`}
                      value={values[`${programHandle}.fChipSideSuperheatTolerance`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.fChipSideSuperheatTolerance`]}
                      invalidText={errors[`${programHandle}.fChipSideSuperheatTolerance`]}
                    />
                  </div>
                </div>
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      labelText="Cap Side Annealing Temperature"
                      id={`['${programHandle}.fCapSideAnnealingTemp']`}
                      name={`['${programHandle}.fCapSideAnnealingTemp']`}
                      value={values[`${programHandle}.fCapSideAnnealingTemp`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.fCapSideAnnealingTemp`]}
                      invalidText={errors[`${programHandle}.fCapSideAnnealingTemp`]}
                    />
                  </div>
                  <div className="bx--col">
                    <TextInput
                      labelText="Chip Side Annealing Temperature"
                      id={`['${programHandle}.fChipSideAnnealingTemp']`}
                      name={`['${programHandle}.fChipSideAnnealingTemp']`}
                      value={values[`${programHandle}.fChipSideAnnealingTemp`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.fChipSideAnnealingTemp`]}
                      invalidText={errors[`${programHandle}.fChipSideAnnealingTemp`]}
                    />
                  </div>
                </div>
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      labelText="Cap Side Final Temperature"
                      id={`['${programHandle}.fCapSideFinalTemp']`}
                      name={`['${programHandle}.fCapSideFinalTemp']`}
                      value={values[`${programHandle}.fCapSideFinalTemp`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.fCapSideFinalTemp`]}
                      invalidText={errors[`${programHandle}.fCapSideFinalTemp`]}
                    />
                  </div>
                  <div className="bx--col">
                    <TextInput
                      labelText="Chip Side Final Temperature"
                      id={`['${programHandle}.fChipSideFinalTemp']`}
                      name={`['${programHandle}.fChipSideFinalTemp']`}
                      value={values[`${programHandle}.fChipSideFinalTemp`]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors[`${programHandle}.fChipSideFinalTemp`]}
                      invalidText={errors[`${programHandle}.fChipSideFinalTemp`]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Accordion>
              <AccordionItem title="Auto-calculated values">
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      id={`${programHandle}.fChipSideLoadingTempDisengaged`}
                      labelText="Chip Side Loading Temperature Disengaged Temp"
                      value={values[`${programHandle}.fChipSideLoadingTempDisengaged`] || 0}
                    />
                  </div>
                  <div className="bx--col">
                    <TextInput
                      id={`${programHandle}.fCapSideLoadingTempDisengaged`}
                      labelText="Cap Side Loading Temperature Disengaged Temp"
                      value={values[`${programHandle}.fCapSideLoadingTempDisengaged`] || 0}
                    />
                  </div>
                </div>
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      id={`${programHandle}.fCapSideBondingTempDisengaged`}
                      labelText="Bonding Disengaged Temp"
                      value={values[`${programHandle}.fCapSideBondingTempDisengaged`] || 0}
                    />
                  </div>
                  <div className="bx--col">
                    <TextInput
                      id={`${programHandle}.fChipSideBondingTempDisengaged`}
                      labelText="Cold Heater Disengaged Temp"
                      value={values[`${programHandle}.fChipSideBondingTempDisengaged`] || 0}
                    />
                  </div>
                </div>
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      id={`${programHandle}.fChipSideSuperheatTempDisengaged`}
                      labelText="Chip Side Superheat Disengaged Temp"
                      value={values[`${programHandle}.fChipSideSuperheatTempDisengaged`] || 0}
                    />
                  </div>
                  <div className="bx--col">
                    <TextInput
                      id={`${programHandle}.fCapSideSuperheatTempDisengaged`}
                      labelText="Cap Side Superheat Disengaged Temp"
                      value={values[`${programHandle}.fCapSideSuperheatTempDisengaged`] || 0}
                    />
                  </div>
                </div>
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      id={`${programHandle}.fChipSideAnnealingTempDisengaged`}
                      labelText="Chip Side Annealing Disengaged Temp"
                      value={values[`${programHandle}.fChipSideAnnealingTempDisengaged`] || 0}
                    />
                  </div>
                  <div className="bx--col">
                    <TextInput
                      id={`${programHandle}.fCapSideAnnealingTempDisengaged`}
                      labelText="Cap Side Annealing Disengaged Temp"
                      value={values[`${programHandle}.fCapSideAnnealingTempDisengaged`] || 0}
                    />
                  </div>
                </div>
                <div className="bx--row">
                  <div className="bx--col">
                    <TextInput
                      id={`${programHandle}.fChipSideFinalTempDisengaged`}
                      labelText="Chip Side Final Disengaged Temp"
                      value={values[`${programHandle}.fChipSideFinalTempDisengaged`] || 0}
                    />
                  </div>
                  <div className="bx--col">
                    <TextInput
                      id={`${programHandle}.fCapSideFinalTempDisengaged`}
                      labelText="Cap Side Final Disengaged Temp"
                      value={values[`${programHandle}.fCapSideFinalTempDisengaged`] || 0}
                    />
                  </div>
                </div>
              </AccordionItem>
            </Accordion>
          </Modal>
        );
      }}
    </Formik>,
    document.body,
  );
}

SetupConfigModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default SetupConfigModal;
