import {
  Box,
  Button,
  Container,
  Grid,
  Header,
  SpaceBetween,
  StatusIndicator,
} from '@cloudscape-design/components';
import React, { useMemo } from 'react';

import { usePLCValue, usePLCWrite } from '../../../features/plc/context';
import PLCToggleButton from '../components/plcToggleButton';
import { usePressHandle, useProgramHandle } from '../hooks/context';

function SystemStatus() {
  const write = usePLCWrite();

  const pressHandle = usePressHandle();
  const programHandle = useProgramHandle();

  const airStatus = usePLCValue(`${programHandle}.AirStatus`);
  const chillerStatus = usePLCValue(`${programHandle}.ChillerStatus`);
  const instronAudio = usePLCValue(`${programHandle}.InstronAudio`);
  const strain = usePLCValue(`${programHandle}.Strain`);

  const lowerHotBlockHeaterSystemOn = usePLCValue(`${programHandle}.bLowerHotBlockHeaterSystemOn`);
  const lowerRearHeaterSystemOn = usePLCValue(`${programHandle}.bLowerRearHeaterSystemOn`);
  const lowerFrontHeaterSystemOn = usePLCValue(`${programHandle}.bLowerFrontHeaterSystemOn`);
  const upperHotBlockHeaterSystemOn = usePLCValue(`${programHandle}.bUpperHotBlockHeaterSystemOn`);
  const upperRearHeaterSystemOn = usePLCValue(`${programHandle}.bUpperRearHeaterSystemOn`);
  const upperFrontHeaterSystemOn = usePLCValue(`${programHandle}.bUpperFrontHeaterSystemOn`);

  const allHeatersOn = useMemo(() => [
    lowerHotBlockHeaterSystemOn,
    lowerRearHeaterSystemOn,
    lowerFrontHeaterSystemOn,
    upperHotBlockHeaterSystemOn,
    upperRearHeaterSystemOn,
    upperFrontHeaterSystemOn,
  ].every((heater) => heater), [
    lowerHotBlockHeaterSystemOn,
    lowerRearHeaterSystemOn,
    lowerFrontHeaterSystemOn,
    upperHotBlockHeaterSystemOn,
    upperRearHeaterSystemOn,
    upperFrontHeaterSystemOn,
  ]);

  return (
    <Container header={<Header>System status</Header>} fitHeight>
      <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }, { colspan: 12 }]}>
        <SpaceBetween size="s">
          <div>
            <Box variant="awsui-key-label">Air</Box>
            <StatusIndicator type={airStatus ? 'success' : 'error'}>
              {airStatus ? 'On' : 'Off'}
            </StatusIndicator>
          </div>
          <div>
            <Box variant="awsui-key-label">Chiller</Box>
            <StatusIndicator type={chillerStatus ? 'success' : 'error'}>
              {chillerStatus ? 'On' : 'Off'}
            </StatusIndicator>
          </div>
        </SpaceBetween>
        <SpaceBetween size="s">
          <div>
            <Box variant="awsui-key-label">Heaters</Box>
            <StatusIndicator type={allHeatersOn ? 'success' : 'warning'}>
              {allHeatersOn ? 'All on' : 'Some (or all) off'}
            </StatusIndicator>
          </div>
          <div>
            <Box variant="awsui-key-label">Instron Audio</Box>
            <StatusIndicator type={instronAudio ? 'success' : 'info'}>
              {instronAudio ? 'On' : 'Off'}
            </StatusIndicator>
          </div>
        </SpaceBetween>
        <SpaceBetween size="s">
          <Button
            onClick={() => { write({ handle: `${programHandle}.Strain`, value: !strain }); }}
            fullWidth
          >
            Trigger Strain (force Instron forward)
          </Button>
          <Button
            onClick={() => { write({ handle: `${programHandle}.bInstronManualOverride`, value: true }); }}
            fullWidth
          >
            Trigger Instron Audio (force PLC forward)
          </Button>
          <PLCToggleButton
            handle={`${pressHandle}.Vacuum`}
            label="vacuum"
            fullWidth
          />
        </SpaceBetween>
      </Grid>
    </Container>
  );
}

export default SystemStatus;
