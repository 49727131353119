import {
  Box,
  Button,
  Header,
  Modal,
  Spinner,
} from '@cloudscape-design/components';
import { getFunctions, httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

function PasswordReset({ userEmail }) {
  const [isOpen, setOpen] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getPasswordResetLink = () => {
    setIsLoading(true);
    const functions = getFunctions();
    const getFirstEmailResetLink = httpsCallable(functions, 'getFirstEmailResetLink');
    getFirstEmailResetLink({ userEmail })
      .then((result) => {
        if (result.data) {
          setResetCode(result.data);
        } else {
          setError('Error getting reset code');
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      getPasswordResetLink();
    } else {
      setResetCode('');
      setError(null);
    }
  }, [isOpen]);

  return (
    <>
      {typeof document === 'undefined'
        ? null
        : ReactDOM.createPortal(
          <Modal
            visible={isOpen}
            header={<Header>Password reset link</Header>}
            onDismiss={() => { setOpen(false); }}
          >
            <p style={{ marginBottom: '1rem' }}>Copy and paste (but do not click) the link below. Each link is valid for ~1 hour and only works once.</p>
            {isLoading ? (
              <Box textAlign="center"><Spinner /></Box>
            ) : null}
            {error ? <p>{error}</p> : null}
            {resetCode ? <code>{`https://parallelfluidics.com/view-quote?actionCode=${resetCode}&email=${encodeURIComponent(userEmail)}`}</code> : null}
          </Modal>,
          document.body,
        )}
      <Button disabled={!userEmail} onClick={() => { setOpen(true); }}>
        Get password reset link
      </Button>
    </>
  );
}

PasswordReset.defaultProps = {
  userEmail: null,
};

PasswordReset.propTypes = {
  userEmail: PropTypes.string,
};

export default PasswordReset;
