import {
  ContentLayout,
  Header,
} from '@cloudscape-design/components';
import {
  collection, getFirestore, query, where,
} from 'firebase/firestore';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from 'reactfire';

import PartsList from '../../components/PartsList';
import PasswordReset from '../../components/PasswordReset';
import UserEmail from '../../components/UserEmail';

function User() {
  const { userID } = useParams();
  const user = useUser(userID);

  if (!user) {
    return null;
  }

  if (user.error) {
    return <p>{user.error}</p>;
  }

  const userPartsQuery = useMemo(() => query(collection(getFirestore(), 'parts'), where('uid', '==', userID)), [userID]);

  return (
    <ContentLayout
      header={(
        <Header
          variant="h1"
          actions={<PasswordReset userEmail={user.email} />}
        >
          <UserEmail userID={userID} />
        </Header>
    )}
    >
      <PartsList partsQuery={userPartsQuery} />
    </ContentLayout>
  );
}

export default User;
