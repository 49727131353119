import {
  Box,
  Button,
  Container,
  Header,
  SpaceBetween,
  Table,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePartAndQuote } from '../../features/firebase';

function PricingSummaryTable({ partId }) {
  const navigate = useNavigate();

  const { quote } = usePartAndQuote(partId);
  const { rows, correctVersion } = useMemo(() => {
    if (!quote) {
      return { correctVersion: false };
    }
    const { pricingV3 } = quote;
    if (!pricingV3) {
      return { correctVersion: false };
    }
    const { breakpoints, calculatedValues } = pricingV3?.values || {};
    if (!breakpoints || !calculatedValues) {
      return { correctVersion: true, rows: [] };
    }

    const {
      uncappedSetupPrice,
      cappedSetupPrice,
      uncappedUnscaledUnitPrice,
      cappedUnscaledUnitPrice,
      uncappedUnitPrice,
      cappedUnitPrice,
    } = calculatedValues;

    return {
      rows: breakpoints.map(({ quantity, multiplier }) => ({
        quantity,
        multiplier,
        uncappedSetupPrice,
        cappedSetupPrice,
        uncappedUnitPrice: uncappedUnitPrice * multiplier + uncappedUnscaledUnitPrice,
        cappedUnitPrice: cappedUnitPrice * multiplier + cappedUnscaledUnitPrice,
      })),
      correctVersion: true,
    };
  }, [quote]);

  if (!correctVersion) {
    return (
      <Container
        header={(
          <Header variant="h2">
            Pricing
          </Header>
        )}
      >
        This quote uses an outdated pricing model. Ask
        {' '}
        <a href="https://slack.com/app_redirect?channel=UT9HGAKC4" target="_blank" rel="noreferrer">Andy</a>
        {' '}
        if you need help.
      </Container>
    );
  }

  return (
    <Table
      header={(
        <Header
          variant="h2"
          actions={rows.length > 0 ? (
            <Button variant="normal" onClick={() => { navigate(`/part/${partId}/pricing`); }}>Edit</Button>
          ) : null}
        >
          Pricing
        </Header>
      )}
      columnDefinitions={[
        { id: 'quantity', header: 'Quantity', cell: (row) => row.quantity },
        { id: 'multiplier', header: 'Multiplier', cell: (row) => row.multiplier },
        { id: 'uncappedSetupPrice', header: 'Uncapped Setup Price', cell: (row) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.uncappedSetupPrice) },
        { id: 'uncappedUnitPrice', header: 'Uncapped Unit Price', cell: (row) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.uncappedUnitPrice) },
        { id: 'cappedSetupPrice', header: 'Capped Setup Price', cell: (row) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.cappedSetupPrice) },
        { id: 'cappedUnitPrice', header: 'Capped Unit Price', cell: (row) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.cappedUnitPrice) },
      ]}
      items={rows}
      empty={(
        <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>Pricing needs review</b>
            <Button onClick={() => { navigate('./pricing'); }}>Review</Button>
          </SpaceBetween>
        </Box>
      )}
    />
  );
}

PricingSummaryTable.propTypes = {
  partId: PropTypes.string.isRequired,
};

export default PricingSummaryTable;
