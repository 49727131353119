import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import {
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { useFirestoreDocData } from 'reactfire';

const ProcessDataContext = createContext({});

function ProcessDataProvider({ children, sessionId }) {
  const { data: session } = useFirestoreDocData(doc(getFirestore(), 'process_logs', sessionId), { idField: 'id' });
  const [offset, setOffset] = useState(20);

  const { startTime, press } = useMemo(() => {
    if (!session) {
      return undefined;
    }
    return {
      startTime: new Date(session.start.toDate()),
      press: session.press,
    };
  }, [session]);

  useEffect(() => {
    if (!session?.end) {
      return;
    }
    setOffset(Math.ceil((
      session.end.toDate() - session.start.toDate()
    ) / 1000 / 60));
  }, [session]);

  const endTime = useMemo(() => {
    if (!startTime) {
      return undefined;
    }
    const end = new Date(startTime);
    end.setSeconds(end.getSeconds() + offset * 60);
    return end;
  }, [startTime, offset]);

  const { data: instronData } = useQuery(
    ['instronData', { startTime, endTime, press }],
    async () => {
      if (!startTime) {
        return {};
      }

      // give ourselves 30s of buffer time
      const startTimeWithBuffer = new Date();
      startTimeWithBuffer.setTime(startTime.getTime() - 30 * 1000);

      // query firestore for instron_data between start and end
      const q = query(
        collection(getFirestore(), 'instron_data'),
        where('startTime', '>', startTimeWithBuffer),
        where('startTime', '<', endTime),
        where('press', '==', press),
      );
      const querySnapshot = await getDocs(q);

      let found = false;
      let retData = {};
      querySnapshot.forEach((snapshot) => {
        if (found) {
          throw Error('Multiple Instron records found. Couldn\'t determine which to use.');
        }
        found = true;
        retData = snapshot.data();
      });
      if (!found) {
        throw new Error('No matching Instron records.');
      }
      return {
        ...retData,
        startTime: retData.startTime.toDate().toISOString(),
        endTime: retData.endTime.toDate().toISOString(),
        timestamps: retData.timestamps.map((t) => t.toDate().toISOString()),
      };
    },
  );

  const { data: plcData, isLoading: isPlcDataLoading } = useQuery(
    ['plcData', { startTime, endTime, press }],
    async () => {
      const functions = getFunctions();
      const { data } = await httpsCallable(functions, 'queryProcessDataV3')(
        { startTime, endTime, press },
      );
      const d = data.map((row) => ({
        ...row,
        timestamp: new Date(row.timestamp),
      }));
      return d;
    },
  );

  const value = useMemo(() => ({
    session,
    instronData,
    plcData,
    isPlcDataLoading,
    offset,
    setOffset,
  }));

  return (
    <ProcessDataContext.Provider value={value}>
      {children}
    </ProcessDataContext.Provider>
  );
}

ProcessDataProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  sessionId: PropTypes.string.isRequired,
};

export default ProcessDataProvider;
export { ProcessDataContext };
