import {
  Button,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import {
  usePLCReady,
  usePLCWrite,
} from '../../../features/plc/context';

function PLCButton({
  variant,
  disabled,
  onClick,
  children,
  fullWidth,
}) {
  const ready = usePLCReady();
  const write = usePLCWrite();
  const handleClick = useCallback(() => { onClick(write); });

  return (
    <Button
      loading={!ready}
      disabled={disabled}
      onClick={handleClick}
      variant={variant}
      fullWidth={fullWidth}
    >
      {children}
    </Button>
  );
}

PLCButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  variant: PropTypes.oneOf(['normal', 'primary', 'link', 'icon', 'inline-icon', 'inline-link']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

PLCButton.defaultProps = {
  variant: 'normal',
  disabled: false,
  fullWidth: false,
};

export default PLCButton;
