import {
  Flashbar,
} from '@cloudscape-design/components';
import { Html5QrcodeScanner } from 'html5-qrcode';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useState,
} from 'react';

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
  const config = {};
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  return config;
};

function QRCodeScanner({
  id,
  qrCodeSuccessCallback,
  qrCodeErrorCallback,
  verbose,
  fps,
  qrbox,
  aspectRatio,
  disableFlip,
}) {
  const [error, setError] = useState(null);

  useEffect(() => {
    // when component mounts
    const config = createConfig({
      fps, qrbox, aspectRatio, disableFlip,
    });
    // Suceess callback is required.
    if (!qrCodeSuccessCallback) {
      setError('qrCodeSuccessCallback is required callback.');
    }
    const html5QrcodeScanner = new Html5QrcodeScanner(id, config, verbose === true);
    html5QrcodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback);

    // cleanup function when component will unmount
    return () => {
      html5QrcodeScanner.clear().catch((err) => {
        console.error('Failed to clear html5QrcodeScanner. ', err);
      });
    };
  }, []);

  if (error) {
    return (
      <Flashbar
        items={[{
          header: `Could not load QR scanner: ${error}`,
          dismissible: false,
          type: 'error',
        }]}
      />
    );
  }

  return (
    <div id={id} />
  );
}

QRCodeScanner.propTypes = {
  id: PropTypes.string.isRequired,
  qrCodeSuccessCallback: PropTypes.func.isRequired,
  qrCodeErrorCallback: PropTypes.func,
  verbose: PropTypes.bool,
  fps: PropTypes.number,
  qrbox: PropTypes.number,
  aspectRatio: PropTypes.number,
  disableFlip: PropTypes.bool,
};

QRCodeScanner.defaultProps = {
  qrCodeErrorCallback: null,
  verbose: false,
  fps: undefined,
  qrbox: undefined,
  aspectRatio: undefined,
  disableFlip: undefined,
};

export default QRCodeScanner;
