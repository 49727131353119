import {
  FormField,
  Select,
} from '@cloudscape-design/components';
import { getApp } from 'firebase/app';
import {
  collection, getFirestore, orderBy, query,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useFirestoreCollectionData } from 'reactfire';

function ProjectSelector({
  setProject,
  value,
  error,
  includeOther,
}) {
  const [internalProjects, setInternalProjects] = useState([]);
  useEffect(() => {
    (async () => {
      const functions = getFunctions(getApp());
      const getInternalPartNumbers = httpsCallable(functions, 'getInternalPartNumbers');

      const { data } = await getInternalPartNumbers();
      setInternalProjects(data);
    })();
  }, []);
  const internalProjectOptions = useMemo(() => internalProjects.map((project) => ({
    value: project.value,
    label: `${project.value} - ${project.title}`,
  })).sort((a, b) => {
    if (a.value < b.value) return 1;
    if (a.value > b.value) return -1;
    return 0;
  }), [internalProjects]);

  const { status: customerProjectStatus, data: customerProjects } = useFirestoreCollectionData(
    query(collection(getFirestore(), 'projects'), orderBy('name', 'desc')),
  );
  const customerProjectOptions = useMemo(
    () => (customerProjects || []).map(({ name, adminLink, filename }) => {
      const partId = adminLink?.match(/.*\/part\/([^/]+)/);
      if (partId && partId.length > 1 && partId[1].length === 20) {
        const quoteId = partId[1].substring(0, 6).toUpperCase();
        return {
          value: name,
          label: `${name} - #${quoteId} (${filename})`,
        };
      }
      return {
        value: name,
        label: `${name} (${filename})`,
      };
    }),
    [customerProjects],
  );

  const options = useMemo(() => {
    const opts = [
      { label: 'Customer Projects', options: customerProjectOptions },
      { label: 'Internal Projects', options: internalProjectOptions },
    ];
    if (includeOther) {
      opts.push({
        label: 'Other',
        options: [
          { value: 'other', label: 'Other...' },
        ],
      });
    }
    return opts;
  }, [customerProjectOptions, internalProjectOptions, includeOther]);

  return (
    <FormField
      label="Project"
      description={(
        <span>
          {'Don\'t see the project you\'re looking for? Add customer projects '}
          <a target="_blank" href="https://rowy.admin.parallelfluidics.com/table/projects" rel="noreferrer">here</a>
          {' or internal projects '}
          <a target="_blank" href="https://www.notion.so/parallelfluidics/Part-Number-Databases-e357a39850184d9ea21b9b1539a3d95f" rel="noreferrer">here</a>
          .
        </span>
      )}
      stretch
      errorText={error}
    >
      <Select
        selectedOption={value}
        onChange={({ detail }) => setProject(detail.selectedOption)}
        filteringType="auto"
        options={options}
        loadingText="Loading projects"
        placeholder="Choose a project"
        statusType={
          internalProjectOptions.length === 0 || customerProjectStatus === 'loading'
            ? 'loading' : 'finished'
        }
      />
    </FormField>

  );
}

ProjectSelector.propTypes = {
  setProject: PropTypes.func.isRequired,
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  error: PropTypes.string,
  includeOther: PropTypes.bool,
};

ProjectSelector.defaultProps = {
  value: null,
  error: '',
  includeOther: false,
};

export default ProjectSelector;
