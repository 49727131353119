import { where } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useMemo,
} from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import DataTable from '../DataTable';
import UserEmail from '../UserEmail';

function SummaryTable({ processType, pageSize }) {
  const linkCell = useCallback(
    (e) => <Link to={`/process-data/${processType}/${e.id}`}>{e.number}</Link>,
    [processType],
  );
  const operatorCell = useCallback(
    (e) => <UserEmail userID={e.user} />,
    [],
  );

  const columnDefinitions = useMemo(() => {
    const baseHeaders = [
      {
        id: 'number',
        header: 'Chip Number',
        cell: (e) => linkCell(e),
      },
      {
        id: 'project',
        header: 'Project',
        cell: (e) => e.project,
      },
      {
        id: 'start',
        header: 'Start',
        cell: (e) => (e.start ? e.start.toDate().toLocaleString() : ''),
      },
      {
        id: 'user',
        header: 'Operator',
        cell: (e) => operatorCell(e),
      },
      {
        id: 'press',
        header: 'Press',
        cell: (e) => e.press,
      },
      {
        id: 'production',
        header: 'Production Run',
        cell: (e) => (e.production ? '✅' : ''),
      },
      {
        id: 'hypothesis',
        header: 'Hypothesis',
        cell: (e) => e.hypothesis,
      },
      {
        id: 'observations',
        header: 'Observations',
        cell: (e) => e.observations,
      },
    ];

    switch (processType) {
      case 'forming': {
        return [
          ...(baseHeaders.slice(0, 5)),
          {
            id: 'material',
            header: 'Material',
            cell: (e) => ({
              cop: 'COP',
              pc: 'PC',
              pmma: 'PMMA',
            }[e.material] || e.material),
          },
          {
            id: 'mass',
            header: 'Mass (g)',
            cell: (e) => e.mass,
          },
          ...(baseHeaders.slice(5, baseHeaders.length)),
        ];
      }

      case 'bonding': {
        return [
          ...(baseHeaders.slice(0, 5)),
          {
            id: 'numberOfChips',
            header: 'Number of Chips',
            cell: (e) => e.numberOfChips || '',
          },
          {
            id: 'orientation',
            header: 'Press Orientation',
            cell: (e) => e.pressOrientation,
          },
          ...(baseHeaders.slice(5, baseHeaders.length)),
        ];
      }
      default: {
        return [];
      }
    }
  }, [processType]);

  const { data: projectNumbers } = useQuery(
    ['getProjectNumbers'],
    async () => {
      const getPartNumbers = httpsCallable(getFunctions(), 'getPartNumbers');

      const { data } = await getPartNumbers();
      return [
        ...(data.customerPartNumbers || []),
        ...(data.internalPartNumbers || []),
      ].map((partNumber) => (partNumber.value));
    },
    {
      initialData: [],
    },
  );

  const filteringOptions = useMemo(() => [
    {
      propertyKey: 'material',
      value: 'cop',
      label: 'COP',
    },
    {
      propertyKey: 'material',
      value: 'pc',
      label: 'PC',
    },
    {
      propertyKey: 'material',
      value: 'pmma',
      label: 'PMMA',
    },
    {
      propertyKey: 'press',
      value: 'press1',
      label: 'Press 1',
    },
    {
      propertyKey: 'press',
      value: 'press2',
      label: 'Press 2',
    },
    {
      propertyKey: 'press',
      value: 'press3',
      label: 'Press 3',
    },
    {
      propertyKey: 'press',
      value: 'press4',
      label: 'Press 4',
    },
    ...projectNumbers.map((projectNumber) => ({
      propertyKey: 'project',
      value: projectNumber,
    }))], [projectNumbers]);

  return (
    <DataTable
      id={processType}
      header={{
        forming: 'Forming Data',
        bonding: 'Bonding Data',
      }[processType]}
      type="process_logs"
      orderField="start"
      filters={[where('process', '==', processType)]}
      pageSize={pageSize}
      columnDefinitions={columnDefinitions}
      filteringOptions={filteringOptions}
      filteringProperties={[
        {
          key: 'material',
          operators: ['='],
          propertyLabel: 'Material',
          groupValuesLabel: 'Materials',
        },
        {
          key: 'press',
          operators: ['='],
          propertyLabel: 'Press',
          groupValuesLabel: 'Presses',
        },
        {
          key: 'project',
          operators: ['='],
          propertyLabel: 'Project',
          groupValuesLabel: 'Project IDs',
        },
      ]}
      stickyColumns={{ first: 2, last: 0 }}
      resizableColumns
    />
  );
}

SummaryTable.propTypes = {
  processType: PropTypes.oneOf(['forming', 'bonding']).isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default SummaryTable;
