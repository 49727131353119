import {
  Header,
  Table,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { StorageImage } from 'reactfire';

function FeedbackSummaryTable({
  header, feedback, optionalFeedback, forceEmpty, empty,
}) {
  const items = useMemo(
    () => [...feedback, ...optionalFeedback].map((item) => ({
      ...item,
      image: (item.image ? <StorageImage storagePath={item.image} alt={item.title} style={{ maxWidth: '100px' }} /> : null),
    })),
    [feedback, optionalFeedback],
  );
  return (
    <Table
      header={header}
      items={forceEmpty ? [] : items}
      empty={empty}
      columnDefinitions={[
        {
          id: 'title',
          header: 'Title',
          cell: (item) => item.title,
        },
        {
          id: 'text',
          header: 'Text',
          cell: (item) => item.text,
          maxWidth: '300px',
        },
        {
          id: 'image',
          header: 'Image',
          cell: (item) => item.image,
          maxWidth: '200px',
        },
        {
          id: 'checkboxText',
          header: 'Checkbox Text',
          // eslint-disable-next-line react/no-unstable-nested-components
          cell: (item) => item.checkboxText || <span style={{ fontStyle: 'italic' }}>Accept changes</span>,
        },
        {
          id: 'category',
          header: 'Category',
          cell: (item) => item.category,
        },
      ]}
    />
  );
}

FeedbackSummaryTable.propTypes = {
  header: PropTypes.node,
  feedback: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
    checkboxText: PropTypes.string,
    category: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  })),
  optionalFeedback: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
    checkboxText: PropTypes.string,
    category: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  })),
  forceEmpty: PropTypes.bool,
  empty: PropTypes.node,
};

FeedbackSummaryTable.defaultProps = {
  header: <Header>Feedback</Header>,
  feedback: [],
  optionalFeedback: [],
  forceEmpty: false,
  empty: null,
};

export default FeedbackSummaryTable;
