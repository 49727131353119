import {
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
  StatusIndicator,
} from '@cloudscape-design/components';
import {
  doc,
  getFirestore,
} from 'firebase/firestore';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import ProcessDataProvider from '../../components/ProcessData/context';
import ProcessGraph from '../../components/ProcessData/graph';
import Overview from '../../components/ProcessData/overview';

export default function ProcessDataPage() {
  const { sessionId } = useParams();
  const { data: session, status } = useFirestoreDocData(doc(getFirestore(), 'process_logs', sessionId || 'nonexistent'), { idField: 'id' });

  if (status === 'loading') {
    return (
      <ContentLayout header={<Header>Process data</Header>}>
        <Container>
          <StatusIndicator type="loading">Loading...</StatusIndicator>
        </Container>
      </ContentLayout>
    );
  }

  if (!sessionId || !session) {
    return (
      <ContentLayout header={<Header>Process data</Header>}>
        <Container>
          <StatusIndicator type="error">Invalid session ID</StatusIndicator>
        </Container>
      </ContentLayout>
    );
  }

  return (
    <ProcessDataProvider sessionId={sessionId}>
      <ContentLayout header={<Header>Process data</Header>}>
        <SpaceBetween direction="vertical" size="l">
          <Overview sessionId={sessionId} />
          <ProcessGraph sessionId={sessionId} />
        </SpaceBetween>
      </ContentLayout>
    </ProcessDataProvider>
  );
}
