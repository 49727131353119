import {
  colorChartsStatusHigh,
  colorChartsStatusInfo,
  colorChartsStatusNeutral,
  colorChartsStatusPositive,
} from '@cloudscape-design/design-tokens';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

function GaugeSkeleton({ title }) {
  return (
    <div style={{ width: '100%', paddingTop: '50%', position: 'relative' }}>
      <svg
        style={{
          position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
        }}
        viewBox="0 0 100 50"
      >
        <text x="50" y="22" textAnchor="middle" alignmentBaseline="middle" fontFamily="Roboto" fontSize={5}>
          {title}
        </text>
        <text x="50" y="29" textAnchor="middle" alignmentBaseline="middle" fontFamily="Roboto" fontSize={6} fontWeight={800}>
          Loading...
        </text>
        <path
          d={d3.arc()({
            innerRadius: 40,
            outerRadius: 45,
            startAngle: -Math.PI / 2,
            endAngle: Math.PI / 2,
          })}
          transform="translate(50, 50)"
          fill={colorChartsStatusInfo}
        />
      </svg>
    </div>
  );
}

GaugeSkeleton.propTypes = {
  title: PropTypes.string.isRequired,
};

function Gauge({
  title,
  temp,
  max,
  target,
  bands,

  // indicators
  showIndicators,
  heaterOn,
  cylinderUp,
}) {
  const arcs = useMemo(() => (bands || []).map((band) => {
    const {
      id, start, end, color,
    } = band;
    return {
      id,
      innerRadius: 40,
      outerRadius: 45,
      startAngle: (start / max - 0.5) * Math.PI,
      endAngle: (end / max - 0.5) * Math.PI,
      fill: {
        positive: colorChartsStatusPositive,
        neutral: colorChartsStatusNeutral,
        info: colorChartsStatusInfo,
        high: colorChartsStatusHigh,
      }[color],
    };
  }), [bands]);

  const currentTempAngle = useMemo(() => {
    const percentage = temp / max;
    return Math.PI * (percentage - 0.5);
  }, [temp, max]);

  return (
    <div style={{ width: '100%', paddingTop: '50%', position: 'relative' }}>
      <svg
        style={{
          position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
        }}
        viewBox="0 0 100 50"
      >
        <text x="50" y="22" textAnchor="middle" alignmentBaseline="middle" fontFamily="Roboto" fontSize={5}>
          {title}
        </text>
        <text x="50" y="29" textAnchor="middle" alignmentBaseline="middle" fontFamily="Roboto" fontSize={6} fontWeight={800}>
          {`${temp}°C`}
        </text>
        <text x="50" y="35" textAnchor="middle" alignmentBaseline="middle" fontFamily="Roboto" fontSize={5} fontStyle="italic">
          {`Target: ${target}${typeof target === 'number' ? '°C' : ''}`}
        </text>
        <path
          d={d3.arc()({
            innerRadius: 40,
            outerRadius: 45,
            startAngle: -Math.PI / 2,
            endAngle: Math.PI / 2,
          })}
          transform="translate(50, 50)"
          fill={colorChartsStatusInfo}
        />
        {arcs.map(({
          id, innerRadius, outerRadius, startAngle, endAngle, fill,
        }) => (
          <path
            key={id}
            d={d3.arc()({
              innerRadius,
              outerRadius,
              startAngle,
              endAngle,
            })}
            transform="translate(50, 50)"
            fill={fill}
          />
        ))}
        <line
          x1={50 + 38 * Math.sin(currentTempAngle)}
          x2={50 + 47 * Math.sin(currentTempAngle)}
          y1={50 - 38 * Math.cos(currentTempAngle)}
          y2={50 - 47 * Math.cos(currentTempAngle)}
          stroke="black"
          strokeWidth="1"
        />

        {showIndicators ? (
          <>
            <rect
              x={15}
              y={40}
              width={32}
              height={8}
              rx={4}
              ry={4}
              fill={heaterOn ? colorChartsStatusPositive : colorChartsStatusNeutral}
            />
            <text x="31" y="44" textAnchor="middle" alignmentBaseline="middle" fontFamily="Roboto" fontSize={4} fill="white">
              Heater
              {' '}
              {heaterOn ? 'on' : 'off'}
            </text>
            <rect
              x={53}
              y={40}
              width={32}
              height={8}
              rx={4}
              ry={4}
              fill={cylinderUp ? colorChartsStatusPositive : colorChartsStatusNeutral}
            />
            <text x="69" y="44" textAnchor="middle" alignmentBaseline="middle" fontFamily="Roboto" fontSize={4} fill="white">
              Cylinder
              {' '}
              {cylinderUp ? 'up' : 'down'}
            </text>
          </>
        ) : null}
      </svg>
    </div>
  );
}

Gauge.propTypes = {
  title: PropTypes.string.isRequired,
  temp: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  bands: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
    color: PropTypes.oneOf(['high', 'positive', 'neutral', 'info']).isRequired,
  })),
  target: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showIndicators: PropTypes.bool,
  heaterOn: PropTypes.bool,
  cylinderUp: PropTypes.bool,
};

Gauge.defaultProps = {
  bands: [],
  showIndicators: true,
  heaterOn: false,
  cylinderUp: false,
};

export default Gauge;
export { GaugeSkeleton };
