import { InlineNotification } from 'carbon-components-react';
import React from 'react';

import { usePLCError } from '../../../features/plc/context';

function PLCError() {
  const error = usePLCError();

  if (!error) {
    return null;
  }

  return (
    <InlineNotification
      kind="error"
      lowContrast
      title="Error connecting to PLC"
      subtitle={error}
      hideCloseButton
      style={{ minWidth: '100%', width: '100%' }}
    />
  );
}

export default PLCError;
