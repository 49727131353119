import {
  FormField,
  Select,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import {
  usePLCValue,
  usePLCWrite,
} from '../../../features/plc/context';

function PLCSelect({ label, handle, options }) {
  const write = usePLCWrite();
  const value = usePLCValue(handle);
  const selectedItem = useMemo(
    () => options.find((option) => option.value === `${value}`),
    [options, value],
  );

  if (label) {
    return (
      <FormField
        label={label}
      >
        <Select
          options={options}
          selectedOption={selectedItem}
          onChange={({ detail }) => {
            write({ handle, value: parseInt(detail.selectedOption.value, 10) });
          }}
        />
      </FormField>
    );
  }
  return (
    <Select
      options={options}
      selectedOption={selectedItem}
      onChange={({ detail }) => {
        write({ handle, value: parseInt(detail.selectedOption.value, 10) });
      }}
    />
  );
}

PLCSelect.propTypes = {
  label: PropTypes.string,
  handle: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

PLCSelect.defaultProps = {
  label: null,
};

export default PLCSelect;
