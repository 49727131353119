import {
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-query';

function UserEmail({ userID, backupEmail }) {
  const { data: userEmail, status, error } = useQuery(
    ['user', userID],
    async () => {
      const functions = getFunctions();
      const getUserIDByEmail = httpsCallable(functions, 'getUserEmailByID');
      const result = await getUserIDByEmail({ userID });
      return result.data;
    },
    {
      enabled: !!userID,
    },
  );

  if (status === 'loading') {
    return <span>{`${userID} (loading email...)`}</span>;
  }

  if (status === 'error') {
    return <span>{`${userID} (Error: ${error.message})`}</span>;
  }

  if (userEmail) {
    return <span>{userEmail}</span>;
  }

  return (
    <span>
      Anonymous User
      {backupEmail ? ` (${backupEmail})` : ''}
    </span>
  );
}

UserEmail.defaultProps = {
  backupEmail: null,
};

UserEmail.propTypes = {
  userID: PropTypes.string.isRequired,
  backupEmail: PropTypes.string,
};

export default UserEmail;
