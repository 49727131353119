import {
  Button,
  ContentLayout,
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import ActiveOrderStatus, { useShippedProjects } from '../../components/ActiveOrders';

function ShippedOrdersPage() {
  const activeOrders = useShippedProjects();
  const navigate = useNavigate();
  return (
    <ContentLayout header={(
      <Header
        actions={<Button variant="normal" onClick={() => { navigate('/orders/active'); }}>See active orders</Button>}
      >
        Past Orders
      </Header>
    )}
    >
      <SpaceBetween direction="vertical" size="l">
        {activeOrders.map((order) => (
          <ActiveOrderStatus
            key={order.projectNumber}
            customerName={order.customerName}
            projectNumber={order.projectNumber}
            shipDate={order.shipDate}
            moldedGoal={order.moldedGoal}
            postmachinedGoal={order.postmachinedGoal}
            bondedGoal={order.bondedGoal}
            connectorizedGoal={order.connectorizedGoal}
          />
        ))}
      </SpaceBetween>
    </ContentLayout>
  );
}

export default ShippedOrdersPage;
